.dialog {
  position: relative;
  top: var(--basis_x16);
  left: 50%;
  transform: translate3d(-50%, 0, 0);

  width: 800px;
  max-width: calc(100% - var(--basis_x8));
  max-height: calc(100% - var(--basis_x32));

  overflow: auto;
  -webkit-overflow-scrolling: touch;

  /* padding: var(--basis_x8); */
  background: var(--background);
  margin-bottom: var(--basis_x8);

  box-shadow:
    0 var(--basis_x16) var(--basis_x32) var(--shadow-color-rgba),
    0 var(--basis_x8) var(--basis_x16) var(--shadow-color-rgba),
    0 var(--basis_x2) var(--basis_x4) var(--shadow-color-rgba),
    0 0 var(--basis_x0_5) var(--shadow-color-rgba);
  border-radius: var(--basis_x2);
}
@media (max-height: 700px) {
  .dialog {
    top: var(--basis_x4);
    max-height: calc(100% - var(--basis_x8));
  }
}
@media (max-width: 600px), (max-height: 600px) {
  .dialog {
    top: 0;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    border-radius: 0;
  }
}
.dialog h1 {
  margin: 0 0 var(--basis_x4) 0;
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(var(--background-contrast-rgb), 0.6);
  cursor: pointer;
}

.dialog h1 {
  display: inline-block;
}

.inputWrapper {
  display: flex;
  align-items: center;
}
.inputWrapper .input {
  flex-grow: 1;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.searchInput {
  width: 100%;

  padding: var(--basis_x4) var(--basis_x8);
  border: 0;
  border-radius: 0;
  background: var(--background);
  color: var(--on-background);
  font-size: var(--basis_x4);
  font-family: var(--font-family);
  outline: none;
}

.filters {
  background: rgba(var(--on-background-rgb), var(--alpha-less));
  padding: var(--basis_x2) 0;

  display: block;
  width: auto;
  white-space: nowrap;
  overflow: auto;
}
.filters button {
  margin: 0 var(--basis_x2) 0 0;
}
.filters button:first-of-type {
  margin-left: var(--basis_x8);
}
.filters button:last-of-type {
  margin-right: var(--basis_x8);
}

.searchResults {
  padding: var(--basis_x4) var(--basis_x8);
}

.searchErrors {  
  color: var(--red);
}

.mathResult {
  font-family: monospace;
  background: rgba(var(--on-background-rgb), var(--alpha-less));
  padding: var(--basis_x2);
  margin: 0 0 var(--basis_x4) 0;
}

.blockRow .blockRowActions {
  display: flex;
  align-items: stretch;
}
@media (any-hover: hover) {
  @media (min-width: 1200px) {
    .blockRow .blockRowActions {
      opacity: 0;
    }
    .blockRow.fakeHover .blockRowActions,
    .blockRow:hover .blockRowActions {
      opacity: 1;
      display: flex;
      align-items: stretch;
    }
  }
}
