.header {
  z-index: 10;
  position: sticky;

  --minus_basis_x4: calc(-1 * var(--basis_x4));
  margin: var(--minus_basis_x4) var(--minus_basis_x4) var(--basis_x4) var(--minus_basis_x4);
  top: 0;

  background: var(--background);
  color: var(--on-background);
}
/*
@supports (backdrop-filter: saturate(180%) blur(20px)) {
  .header {
    background: rgba(var(--background-rgb), 0.8);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
  }
}
*/
@media (max-width: 1000px) {
  .header {
    --minus_basis_x2: calc(-1 * var(--basis_x2));
    --minus_basis_x4: calc(-1 * var(--basis_x4));
    margin: var(--minus_basis_x4) var(--minus_basis_x2) var(--basis_x4) var(--minus_basis_x2);
  }
}



.header .headerBar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  padding: var(--basis_x2) var(--basis_x4);
  gap: var(--basis_x4);

  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 1000px) {
  .header .headerBar {
    padding: var(--basis_x2);
  }
}
.header .headerBar h2{
  flex-grow: 1;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.header .headerBar button{
  margin-top: 0;
  margin-bottom: 0;
}

.notificationBanner {
  padding: var(--basis_x2);
}
