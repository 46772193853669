.icon {
  display: inline-block;
  height: var(--body-font-size) !important;
  width: var(--body-font-size) !important;
  vertical-align: middle;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: calc(0.5 * var(--body-font-size));
  flex-shrink: 0;
}
.icon.square {
  border-radius: 0px;
}
.icon.round {
  border-radius: 100px;
}
.icon.text_document {
  color: var(--on-background);
  background: var(--background-contrast);

  overflow: hidden;
  height: var(--body-font-size) !important;
  width: calc(0.8 * var(--body-font-size)) !important;

  /* font-size: calc(0.08 * var(--body-font-size));
  padding: calc(0.025 * var(--body-font-size)) calc(0.05 * var(--body-font-size));
  line-height: calc(0.14 * var(--body-font-size)); */

  font-family: monospace;
  white-space: pre-wrap;
  word-break: break-word;
  font-size: calc(0.01 * var(--body-font-size));
  padding: calc(0.08 * var(--body-font-size)) calc(0.08 * var(--body-font-size));
  line-height: calc(0.02 * var(--body-font-size));
  -webkit-text-stroke: calc(var(--body-font-size)*0.02) currentColor;
  /* border-radius: calc(var(--body-font-size)*0.06); */
}
/* check for dark mode*/
@media (prefers-color-scheme: dark) {
  .icon.text_document {
    color: var(--on-background);
    background: var(--background-contrast);
  }
}
.icon.text_document > div {
  margin-block-end: calc(0.06 * var(--body-font-size));
}
.icon.text_document .coverphoto {
  height: calc(0.25 * var(--body-font-size));
  background-color: currentColor;
  background-position: center;
  background-size: cover;
  opacity: var(--alpha);
  box-shadow: 0 0 0 calc(var(--body-font-size)*0.02) currentColor;
  /* border-radius: calc(var(--body-font-size)*0.01); */
  width: calc(100% - calc(var(--body-font-size)*0.02));
  margin: calc(var(--body-font-size)*0.02) auto calc(var(--body-font-size)*0.1) auto;
}
.icon img {
  width: 100%;
  height: 100%;
}
