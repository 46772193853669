:root {
  --white: #fff;
  --white-rgb: 255, 255, 255;
  --on-white: #502379;
  --grey: #ede8f1;
  --grey-rgb: 237, 232, 241;
  --dark-grey: #aa94c0;
  --dark-grey-rgb: 170, 148, 192;

  --purple: #502379;
  --purple-rgb: 80, 35, 121;
  --on-purple: #fff;
  --purple-dark: #140022;
  --purple-dark-rgb: 20, 0, 34;
  --on-purple-dark: #fff;

  --alpha-more: 0.8;
  --alpha: 0.6;
  --alpha-less: 0.2;

  --blur: 20px;

  --yellow: #FDC220;
  --yellow-rgb: 253, 194, 32;
  --on-yellow: #fff;
  --green: #1BBE6F;
  --green-rgb: 27, 190, 111;
  --on-green: #fff;
  --blue: #82D0F4;
  --blue-rgb: 130, 208, 244;
  --on-blue: #000;
  --red: #E63E12;
  --red-rgb: 230, 62, 18;
  --on-red: #fff;

  --timing: .2s ease-in-out;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', 'Noto Kufi Arabic', 'Geeza Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  /*
    Ubuntu = Volt (It includes latin and arabic characters.)

    Noto Kufi Arabic = A Noto font from Google (maybe it's installed)
    Geeza Pro = macOS Arabic Default

    -apple-system, BlinkMacSystemFont = macOS Default
    Helvetica Neue = Old macOS Default
    Segoe UI = Windows Default
    Roboto = Android Default
    Fira Sans = Firefox OS Default
    Oxygen, Cantarell, Droid Sans = Linux

    sans-serif = Fallback
  */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

:root,
.basis_x1 {
  --basis: 1rem;
}
.basis_0_8 {
  --basis: 0.8rem;
}
.basis_0_6 {
  --basis: 0.6rem;
}
.basis_0_4 {
  --basis: 0.4rem;
}
.basis_0_3 {
  --basis: 0.3rem;
}

@media (max-width: 1000px) {
  :root,
  .basis_x1 {
    --basis: 0.8rem;
  }
}
@media (max-width: 800px) {
  :root,
  .basis_x1,
  .basis_0_8,
  .basis_0_6 {
    --basis: 0.4rem;
  }
}
@media (max-width: 500px) {
  :root,
  .basis_x1,
  .basis_0_8,
  .basis_0_6,
  .basis_0_4 {
    --basis: 0.3rem;
  }
}

:root,
.basis_x1,
.basis_0_8,
.basis_0_6,
.basis_0_4,
.basis_0_3 {
  --basis_x0_2: calc(0.2 * var(--basis));
  --basis_x0_5: calc(0.5 * var(--basis));
  --basis_x2: calc(2 * var(--basis));
  --basis_x4: calc(4 * var(--basis));
  --basis_x8: calc(8 * var(--basis));
  --basis_x16: calc(16 * var(--basis));
  --basis_x32: calc(32 * var(--basis));
  --basis_x64: calc(64 * var(--basis));
  --font-add: 1rem;
  --body-font-size: calc(var(--font-add) + var(--basis));
  --body2-font-size: calc(var(--font-add) + var(--basis_x0_5));
}

html {
  scroll-behavior: smooth;

  --background: var(--white);
  --background-rgb: var(--white-rgb);
  --background-contrast: var(--grey);
  --background-contrast-rgb: var(--grey-rgb);
  --on-background: var(--purple);
  --on-background-rgb: var(--purple-rgb);
  --on-background-contrast: var(--purple-dark);
  --on-background-contrast-rgb: var(--purple-dark-rgb);
  --shadow-color: var(--purple);
  --shadow-color-rgb: var(--purple-rgb);
  --shadow-color-rgba: rgba(var(--purple-rgb), var(--alpha-less));

  --input-background: var(--background);
  --constant-button-color: var(--background);
  --image-frame-line: inset 0 0 0 1px rgba(var(--on-background-contrast), var(--alpha-less));
}
@media (prefers-color-scheme: dark) {
  html {
    --background: var(--purple-dark);
    --background-rgb: var(--purple-dark-rgb);
    --background-contrast: var(--purple);
    --background-contrast-rgb: var(--purple-rgb);
    --on-background: var(--white);
    --on-background-rgb: var(--white-rgb);
    --on-background-contrast: var(--dark-grey);
    --on-background-contrast-rgb: var(--dark-grey-rgb);
    --shadow-color: var(--purple-dark);
    --shadow-color-rgb: var(--purple-dark-rgb);
    --shadow-color-rgba: rgba(var(--purple-dark-rgb), var(--alpha-less));

    --input-background: var(--background);
    --constant-button-color: var(--background-contrast);
    --image-frame-line: inset 0 0 0 1px rgba(var(--on-background-rgb), var(--alpha-less));
  }
}

html[lang="ar"] { /* for arabic */
  letter-spacing: 0 !important;
}

body {
  background: var(--background-contrast);
  color: var(--on-background);
  font-size: var(--body-font-size);
}

code,
code * {
  font-family: 'Ubuntu Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
code.filled,
kbd {
  padding: var(--basis_x0_2) var(--basis_x0_5);
  margin: 0 var(--basis_x0_2);
  border-radius: var(--basis_x0_5);
  background: rgba(var(--background-rgb), var(--alpha));
  color: var(--on-background);
  font-weight: bold;
}

pre.hljs {
  margin: var(--basis) 0;
}

footer {
  padding: var(--basis_x4);
  text-align: center;
}

h1,
h2,
h3,
.type_h1,
.type_h2,
.type_h3 {
  scroll-margin-top: calc(10 * var(--basis));
}

h1,
.type_h1 {
  font-size: calc(var(--font-add) + var(--basis_x4));
  line-height: 1;
  /* margin: 0 0 var(--basis) 0; */
  margin: var(--basis_x4) 0 var(--basis) 0;
  text-decoration: inherit;
  font-weight: bold;
  --prefix-icon-size: calc(var(--font-add) + var(--basis_x4));

  background: var(--on-background);
  color: var(--background);
  padding: var(--basis) var(--basis_x2);
  display: inline-block;
}
@media (prefers-color-scheme: dark) {
  h1,
  .type_h1 {
    color: var(--background-contrast);
  }
}

h2,
.type_h2 {
  font-size: calc(var(--font-add) + calc(3 * var(--basis)));
  margin: var(--basis_x4) 0 var(--basis) 0;
  text-decoration: inherit;
  font-weight: bold;
  --prefix-icon-size: calc(var(--font-add) + calc(3 * var(--basis)));
}

h3,
.type_h3 {
  font-size: calc(var(--font-add) + (1.5 * var(--basis)));
  margin: var(--basis_x4) 0 var(--basis) 0;
  text-decoration: inherit;
  font-weight: bold;
  --prefix-icon-size: calc(var(--font-add) + (1.5 * var(--basis)));
}

h1.yellow,
h2.yellow,
h3.yellow {
  color: var(--yellow);
}
h1.green,
h2.green,
h3.green {
  color: var(--green);
}
h1.blue,
h2.blue,
h3.blue {
  color: var(--blue);
}
h1.red,
h2.red,
h3.red {
  color: var(--red);
}

a,
a:hover,
a:visited,
a:focus {
  color: inherit;
  text-decoration: inherit;
}

p,
.type_p {
  color: inherit;
  /* width: calc(var(--basis_x16) + var(--basis_x8)); */
  max-width: 100%;
  margin: var(--basis) 0;
  text-decoration: inherit;
  font-size: calc(var(--font-add) + var(--basis));
  --prefix-icon-size: calc(var(--font-add) + var(--basis));
}

.type_caption {
  color: inherit;
  max-width: 100%;
  margin: var(--basis) 0;
  text-decoration: inherit;
  font-size: var(--font-add);
  --prefix-icon-size: calc(var(--font-add) + var(--basis));
  opacity: var(--alpha-more);
}

p a,
p a:hover,
p a:visited,
p a:focus {
  text-decoration: underline;
}
p a:hover{
  opacity: var(--alpha-more);
}

ul,
ol {
  margin-inline-start: var(--basis_x4);
  max-width: calc(100% - var(--basis_x4));
}

.body2 {
  font-size: var(--body2-font-size);
}

textarea,
div[contentEditable=true],
input[type="text"]:not(#react-select-3-input),
input[type="email"],
input[type="url"] {
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
}

button,
textarea,
div[contentEditable=true],
input[type="text"]:not(#react-select-3-input),
input[type="email"],
input[type="url"],
select,
.wrapped_select,
.type_button {
  min-width: 0px;
  outline: none;
  border: none;
  margin: var(--basis);
}

button,
textarea,
input[type="text"]:not(#react-select-3-input),
input[type="email"],
input[type="url"],
select,
.wrapped_select,
.type_button {
  font-size: var(--body-font-size);
}
textarea,
div[contentEditable=true],
input[type="text"]:not(#react-select-3-input),
input[type="email"],
input[type="url"] {
  padding: var(--basis);
  background: var(--input-background);
  color: var(--on-background);
  --input-box-shadow: inset 0 0 0 var(--basis_x0_2) var(--on-background-contrast);
  box-shadow: var(--input-box-shadow);
  transition: box-shadow var(--timing);
}
.inputBorder {
  --input-box-shadow: inset 0 0 0 var(--basis_x0_2) var(--on-background-contrast);
  box-shadow: var(--input-box-shadow);
}
textarea.inverted,
div[contentEditable=true].inverted,
input[type="text"].inverted,
input[type="email"].inverted,
input[type="url"].inverted {
  background: var(--on-background);
  color: var(--input-background);
  --input-box-shadow: inset 0 0 0 var(--basis_x0_2) var(--on-background-contrast);
}

textarea:focus,
div[contentEditable=true]:focus,
input[type="text"]:not(#react-select-3-input):focus,
input[type="email"]:focus,
input[type="url"]:focus {
  background: var(--input-background);
  color: var(--on-background);
  --input-box-shadow: inset 0 0 0 var(--basis_x0_2) var(--on-background);
}

textarea,
div[contentEditable=true] {
  resize: vertical;
  padding-bottom: var(--basis);
  min-height: 20px; /* TODO: Find out why this is value works. */
}

button,
select,
.wrapped_select,
.type_button {
  font-weight: bold;
  transition: transform var(--timing);
  position: relative;
  cursor: pointer;
  padding: var(--basis) var(--basis_x2);

  background-color: var(--button-background) !important;
  color: var(--button-color) !important;
}

button.default,
select,
.wrapped_select,
.type_button.default {
  --button-background: var(--on-background);
  --button-color: var(--constant-button-color);
  background-color: var(--button-background) !important;
  color: var(--button-color) !important;

  --border-radius: 0;
}
button[disabled]{
  cursor: default;
}
.type_button{
  cursor: text;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 6px);
  background-position-y: 50%;
  padding: var(--basis) calc(var(--basis_x2) + 14px) var(--basis) var(--basis_x2);

  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
}
@media (prefers-color-scheme: dark) {
  select {
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  }
}

button,
.clickable_card{
  --borderRadius: calc(0.3 * var(--body-font-size));
  border-radius: var(--borderRadius);
}
button.default,
button.square,
.clickable_card.square {
  --borderRadius: 0;
}
button.yellow {
  --button-background: var(--yellow);
  --button-color: var(--on-yellow);
}
button.green {
  --button-background: var(--green);
  --button-color: var(--on-green);
}
button.blue {
  --button-background: var(--blue);
  --button-color: var(--on-blue);
}
button.red {
  --button-background: var(--red);
  --button-color: var(--on-red);
}
button.text {
  --button-background: transparent;
  --button-color: var(--on-background);
}
button:focus,
.type_button:focus {
  --button-background: var(--background-contrast) !important;
  --button-color: var(--on-background) !important;
}
/* button:not([disabled]):hover{
  transform-origin: center center;
  transform: scale(1.05);
} */
button:not([disabled]).fakeHover:before,
button:not([disabled]):hover:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--background-contrast);
  /* mix-blend-mode: multiply; */
  opacity: 0.3;
  border-radius: var(--borderRadius);
}
button.text:not([disabled]).fakeHover:before,
button.text:not([disabled]):hover:before {
  opacity: var(--alpha-less);
  mix-blend-mode: normal;
  background-color: var(--on-background);
}
button.choosen:after {
  content: "";
  position: absolute;
  top: calc(-1 * var(--basis_x0_5));
  right: calc(-1 * var(--basis_x0_5));
  bottom: calc(-1 * var(--basis_x0_5));
  left: calc(-1 * var(--basis_x0_5));
  box-shadow: 0 0 0 var(--basis_x0_5) var(--on-background);
}
button:focus.choosen:after {
  box-shadow: 0 0 0 var(--basis_x0_5) var(--on-background-contrast);
}

.buttonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
}
.buttonRow button {
  --margin-right: calc(var(--basis) * 1.5);
  margin: var(--basis) var(--margin-right) calc(var(--basis) * .5) 0;
}
.buttonRow button:last-of-type {
  margin-right: 0;
}
.buttonRow.usesLinks button:last-of-type {
  margin-right: var(--margin-right);
}
.buttonRow.usesLinks a:last-of-type button {
  margin-right: 0;
}

button.hasIcon {
  display: inline-flex;
  gap: var(--basis);
  justify-content: space-between;
}
button.hasIcon .icon {
  height: calc(1.1575 * var(--body-font-size));
  width: auto;
  vertical-align: middle;

  --bg-color: var(--button-color);
  --fg-color: var(--button-background);
}
button.hasIcon .icon.big {
  height: var(--basis_x8);
  width: auto;
}

button.hasIcon .icon.image {
  height: var(--basis_x16);
  margin-top: calc(-1 * var(--basis));
  margin-right: calc(-2 * var(--basis)) !important;
  margin-bottom: calc(-1 * var(--basis));
  margin-left: calc(-2 * var(--basis)) !important;
}
button.hasIcon span{
  vertical-align: middle;
}

.clickable_card {
  position: relative;
  cursor: pointer;
  padding: calc(0.5 * var(--body-font-size));
  z-index: 1;
}
.clickable_card:before {
  z-index: -1;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  pointer-events: none;

  border-radius: var(--borderRadius);

  background-color: rgba(var(--on-background-rgb), 0);
}
.clickable_card.active:before,
.clickable_card:hover:before {
  background-color: rgba(var(--on-background-rgb), var(--alpha-less));
}

hr {
  margin: var(--basis_x4) 0;
  border: none;
  height: var(--basis_x0_5);
  background: var(--on-background);
}

blockquote {
  position: relative;
  padding-left: var(--basis);
}
blockquote::before {
  /* content: "”"; */
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(-1 * var(--basis_x4));
  font-size: var(--basis_x4);
  border-right: var(--basis_x0_2) solid currentColor;
  width: var(--basis_x4);
  text-align: right;
  padding-right: var(--basis_x0_5);
  box-sizing: border-box;
}

img.emoji {
   height: 1em;
   width: 1em;
   margin: 0 .05em 0 .1em;
   vertical-align: -0.1em;
}

@media (max-width: 1000px) {
  .hideOnSmallScreen {
    display: none;
  }
}
@media (min-width: 1001px) {
  .hideOnBigScreen {
    display: none;
  }
}
@media (max-width: 1200px) {
  .hideOnScreenSmallerThan1200px {
    display: none;
  }
}
@media (min-width: 1201px) {
  .hideOnScreenBiggerThan1200px {
    display: none;
  }
}



/* reset/remove select styles */
.wrapped_select select {
  /* A reset of styles, including removing the default dropdown arrow */
  appearance: none;
  /* Additional resets for further consistency */
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;

  padding: var(--basis) var(--basis_x2);
}
.wrapped_select select::-ms-expand {
  display: none;
}

/* wrap select in new styles */
.wrapped_select {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.wrapped_select,
.wrapped_select select{
  width: calc(12 * var(--basis));
  padding: var(--basis) var(--basis_x2);
}
.wrapped_select select {
  margin: calc(-1 * var(--basis)) calc(-1 * var(--basis_x2));
}
.wrapped_select:before {
  z-index: 1;
  content: attr(placeholder);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: inherit;
  padding: var(--basis) var(--basis_x2);
  pointer-events: none;
}
.wrapped_select:after {
  content: '';
  z-index: 2;
  position: absolute;
  top: 50%;
  right: var(--basis_x2);
  height: var(--basis);
  width: var(--basis_x2);
  margin-top: calc(-1 * var(--basis_x0_5));
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  background-color: currentColor;
  pointer-events: none;
}

div[contenteditable].hide_border{
  box-shadow: none;
}
@media (any-hover: hover) {
  textarea.show_border_on_active,
  div[contentEditable=true].show_border_on_active,
  input[type="text"].show_border_on_active:not(#react-select-3-input),
  input[type="email"].show_border_on_active,
  input[type="url"].show_border_on_active {
    box-shadow: inset 0 0 0 var(--basis_x0_2) transparent;
  }
  textarea.show_border_on_active:hover,
  div[contentEditable=true].show_border_on_active:hover,
  input[type="text"].show_border_on_active:hover:not(#react-select-3-input),
  input[type="email"].show_border_on_active:hover,
  input[type="url"].show_border_on_active:hover {
    box-shadow: var(--input-box-shadow);
  }
  textarea.show_border_on_active:focus,
  div[contentEditable=true].show_border_on_active:focus,
  input[type="text"].show_border_on_active:focus:not(#react-select-3-input),
  input[type="email"].show_border_on_active:focus,
  input[type="url"].show_border_on_active:focus {
    box-shadow: var(--input-box-shadow);
  }
}

.roundMenuItem {
  border-radius: var(--basis) !important;
  margin: 0 var(--basis_x2) !important;
  padding: var(--basis) var(--basis_x2) !important;
}

div[contentEditable=true].type_h1,
div[contentEditable=true].type_h2,
div[contentEditable=true].type_h3 {
  margin: var(--basis_x4) 0 var(--basis) 0 !important;
}
