.reactionButton:not(.selected) {
  filter: saturate(0) brightness(0);
}
@media (prefers-color-scheme: dark) {
  .reactionButton:not(.selected) {
    filter: saturate(0) brightness(1.5);
  }
}

.emoji {
  height: 100%;
  width: 100%;
}
