.app {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  /* text-align: center; */

  min-height: 100vh;

  background: var(--background);
  color: var(--on-background);
}
.app.isStartpage {
  background: var(--background-contrast);
}
