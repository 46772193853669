.drag_over.drag_active {
  position: relative;
}
.drag_over.drag_active:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow:
    0 0 0 var(--basis) rgba(var(--on-background-rgb), 0.2),
    inset 0 0 0 1000px rgba(var(--on-background-rgb), 0.2);
}
