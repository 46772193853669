.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  font-size: calc(0.8 * var(--body-font-size));
}

.title {
  vertical-align: middle;
  font-weight: bold;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.additionalInfos div[importance="less"] {
  opacity: var(--alpha);
}

.tag {
  display: inline-block;  
  padding: var(--basis_x0_2) var(--basis_x0_5);
  margin: var(--basis_x0_5);
  border-radius: var(--basis_x0_5);
  background: rgba(var(--on-background-rgb), var(--alpha-more));
  color: var(--background);
  font-weight: bold;
}

.root {
  display: flex;
  align-items: flex-start;
}

.root[data-size='icon'] {
  /* background: red !important; */
  flex-direction: column;
  align-items: center;
  gap: var(--basis_x2);
  padding: var(--basis) var(--basis_x2);
}
.root[data-size='icon'] .icon {
  --body-font-size: var(--basis_x16);
  margin: 0 !important;
}

.root[data-size='icon'] .content {
  display: inline;
  max-width: 100%;
  font-size: calc(0.6 * var(--body-font-size));
}


.root[data-size='icon'] .additionalInfos > div{
  white-space: nowrap !important;
  text-overflow: ellipsis;
  overflow: hidden;
}
