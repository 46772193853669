.snackbar {
  border-radius: 0 !important;
  padding: var(--basis) var(--basis_x4) !important;
}
.snackbar.default {
  background-color: var(--on-background);
  color: var(--background);
}

.snackbar.success {
  background-color: var(--green);
  color: var(--on-green);
}
.snackbar.error {
  background-color: var(--red);
  color: var(--on-red);
}
.snackbar.warning {
  background-color: var(--yellow);
  color: var(--on-yellow);
}
.snackbar.info {
  background-color: var(--blue);
  color: var(--on-blue);
}
