.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;

  min-height: 100vh;
}
.app.spine_aligned {
  text-align: unset;
  align-items: flex-start;
}
.app.spine_aligned:dir(rtl) {
  align-items: flex-start;
}

.app .items {
  margin-top: var(--basis_x2);
  margin-bottom: 20vh;
}

.app .coverphoto {
  position: relative;
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
  background-color: var(--background-contrast);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: var(--basis_x4);
  height: 20rem;
}
@media (max-width: 1000px) {
  .app .coverphoto{
    height: 17rem;
  }
}
@media (max-width: 800px) {
  .app .coverphoto{
    height: 11rem;
  }
}
@media (max-width: 500px) {
  .app .coverphoto{
    height: 8rem;
  }
}

/* .app .icon {
  display: inline-block;
  width: auto;
  height: auto;
  font-size: 0;
  border-radius: 100%;
  margin-bottom: 0;
} */

.icon {
  display: block;
  position: relative;
  /* background-color: var(--background-contrast); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  border-radius: 1000px;

  --icon-size-multiplier: 20;
  --icon-size: calc(var(--icon-size-multiplier) * var(--basis));
  width: var(--icon-size);
  height: var(--icon-size);
}
.icon img {
  width: 100% !important;
  height: 100% !important;
}
/*
.icon:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(../images/profile_frame.png);
  background-size: cover;
  border-radius: 100%;
}
*/
.icon.coverphotoIsSet{
  margin-top: calc((-2 + (-0.5 * var(--icon-size-multiplier))) * var(--basis)) !important;
}
@media (max-width: 800px) {
  .icon{
    --icon-size-multiplier: 30;
  }
}

.app .items button {
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  text-align: unset;
}
.app .items p {
  margin-bottom: 0;
}
.app .contentWrapper{
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: var(--basis_x16);
}
@media (max-width: 800px) {
  .app .contentWrapper,
  .app .items,
  .app .items button{
    width: 100%;
  }
}

/* correct the spacing between blocks */
.app .items .type_p,
.app .items p {
  margin: 0;
}
.app .items > div {
  margin-top: var(--basis);
}

.viewer {
  min-height: 100vh;
  padding: var(--basis_x4);
}
